<template>
  <div class="attendance__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>岗位考勤管理
    </div>
    <div class="content">
      <a-row class="content__header">
        <a-col :span="6">
          <div class="recruitUse_text">
            <a-row>
              <a-col :span="4">项目部:</a-col>
              <a-col :span="12">
                <a-select
                  ref="select"
                  :options="Departmentlist"
                  placeholder="请选择项目部"
                  @change="changeDepartment"
                  style="width: 200px"
                  allowClear
                  v-model="projectDepartmentId"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="recruitUse_text">
            <a-row>
              <a-col :span="5">项目名称:</a-col>
              <a-col :span="12">
                <a-select
                  ref="select"
                  :options="Projectlist"
                  placeholder="请选择项目名称"
                  @change="changeProjectlist"
                  style="width: 200px"
                  allowClear
                  v-model="projectId"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="recruitUse_text">
            <a-row>
              <a-col :span="6">工种/专业:</a-col>
              <a-col :span="12">
                <a-select
                  ref="select"
                  :options="Positionlist"
                  placeholder="请选择工种/专业"
                  style="width: 200px"
                  allowClear
                  v-model="demandId"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="recruitUse_text">
            <a-row>
              <a-col :span="6">任务状态:</a-col>
              <a-col :span="12">
                <a-select
                  placeholder="请选择任务状态"
                  style="width: 200px"
                  allowClear
                  v-model="status"
                >
                  <a-select-option :value="0">
                    任务终止
                  </a-select-option>
                  <a-select-option :value="1">
                    进行中
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="text-align: right;margin-bottom:10px" v-perms="'search'">
          <a-button type @click="handleReset">重置</a-button>
          <a-button type="primary" @click="search">查询</a-button>
        </a-col>
      </a-row>
      <div class>
        <p-table
          ref="table"
          :row-selection="rowSelection"
          :sourceData="getDataApi"
          :columns="columns"
          rowKey="id"
          :scorll="{y: 100}"
          extraHeight="500"
          download
          fileName="考勤管理"
          @change="handleTableChange"
        >
          <div class="table__header_left" slot="headerLeft">
            <a-button
              type="primary"
              :disabled="!selectedRows.length"
              @click="messageVisible = true"
              v-perms="'message-all'"
            >消息群发</a-button>
            <a-button type="primary" :disabled="!selectedRows.length" @click="handleStop" v-perms="'stop'">任务终止</a-button>
          </div>
          <template slot="operation" slot-scope="text, record">
            <div style="display: flex">
              <a-button type="link" class="operation_btn" @click="upload(record)" v-perms="'look'">查看全部考勤</a-button>
              <a-button
                type="link"
                v-if="handleIfShow(record)"
                class="operation_btn"
                @click="reject(record)"
                v-perms="'reject'"
              >驳回考勤</a-button>
            </div>
          </template>
        </p-table>
        <a-modal
          width="600px"
          v-model="messageVisible"
          cancelText
          okText
          title="消息群发"
          :confirmLoading="messageLoading"
          @ok="Messaging(message)"
          @cancel="handleModalClose"
        >
          <div class="attendance_date">
            <a-textarea placeholder="请输入消息内容" :rows="4" v-model="message" />
          </div>
        </a-modal>
      </div>
    </div>
    <attendanceDialog ref="attendanceDialog" />
  </div>
</template>

<script>
import attendanceDialog from './attendance-dialog.vue'
import {
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  enterpriseEmploymentPosition,
  employmentEmployList,
  employmentEmployInfo,
  employmentEmployReject,
  employmentEmployStop,
  employmentEmployMessaging
} from '@/api/recruitUse'

import moment from 'moment'
import { download } from '@/api/materialShop'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    customRender: (text, record, index) => index + 1
  },
  {
    title: '工种/专业',
    download: true,
    dataIndex: 'positionName'
  },
  {
    title: '姓名',
    download: true,
    dataIndex: 'realName'
  },
  {
    title: '性别',
    download: true,
    dataIndex: 'sex',
    customRender: text => (text === 0 ? '男' : '女')
  },
  {
    title: '年龄',
    download: true,
    dataIndex: 'age'
  },
  {
    title: '联系电话',
    download: true,
    dataIndex: 'phone'
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    download: true,
    customRender: text => (text === 1 ? '进行中' : '任务终止')
  },
  {
    title: '今日考勤打卡',
    dataIndex: 'toDay',
    download: true,
    customRender: (text, record) => `${record.toDay == null ? '' : record.toDay}`
  },
  {
    title: '今日考勤驳回状态',
    width: 160,
    download: true,
    dataIndex: 'attendanceStatus',
    // customRender: (text, record) => `${record.attendanceStatus == 0 ? `${ record.toDay == null ? '未打卡' : '未驳回'}` : '已驳回'}`
    customRender: (text, record) =>
      `${record.toDay == null ? '未打卡' : `${record.attendanceStatus == 0 ? '未驳回' : '已驳回'}`}`
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 240,
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  components: {
    attendanceDialog
  },
  data () {
    return {
        params: [],
      loading: false,
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      month: moment().format('YYYY-MM'),
      tableData: [],
      modelTableData: [],
      columns,
      visible: false,
      messageVisible: false,
      message: '',
      disabledDate: time => {
        return moment(time).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
      },
      userId: null,
      selectedRows: [],
      selectedRowKeys: [],
      position: undefined,
      projectDepartmentId: undefined,
      projectId: undefined,
      demandId: undefined,
      status: undefined,
      messageLoading: false
    }
  },

  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.status === 2
          }
        })
      }
    },
    columns1 () {
      let days
      const month = moment(this.month).format('MM')
      const currentMonth = moment().format('MM')
      if (currentMonth !== month) {
        days = moment(this.month).daysInMonth()
      } else {
        days = moment().format('D')
      }
      const column = [{ title: '姓名', width: 100, dataIndex: 'userName', key: 'userName' }]
      for (let i = 1; i <= days; i++) {
        column.push({ title: `${month}月${i}日`, dataIndex: `time${i}`, key: '1', width: 100 })
      }
      return column
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      employmentEmployDepartment()
        .then(res => {
          res.data.forEach(v => {
            v.label = v.projectDepartment
            v.value = v.projectDepartmentId
          })
          this.Departmentlist = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.search()
    },
    getDataApi (params) {
      const params1 = {
        ...params,
        position: this.position,
        projectDepartmentId: this.projectDepartmentId,
        projectId: this.projectId,
        demandId: this.demandId,
        demandStatus: this.status
      }
      return new Promise(resolve => {
        employmentEmployList(params1).then(res => {
          resolve({
            ...res,
            data: res.data.map(item => ({
              ...item,
              id: item.userId + item.demandId
            }))
          })
        })
      })
    },
    search () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.position = undefined
      this.projectDepartmentId = undefined
      this.projectId = undefined
      this.demandId = undefined
      this.status = undefined
      this.Projectlist = []
      this.Positionlist = []
    },
    // 获取项目名称
    changeDepartment (val) {
      this.position = ''
      this.demandId = undefined
      this.projectId = undefined
      this.Projectlist = []
      this.Positionlist = []
      if (val) {
        enterpriseEmploymentManagement({
          projectDepartmentId: val
        })
          .then(res => {
            res.data.forEach(v => {
              v.label = v.projectName
              v.value = v.projectId
            })
            this.Projectlist = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    // 获取工种/专业
    changeProjectlist (val) {
      this.position = ''
      this.demandId = undefined
      this.Positionlist = []
      if (val) {
        enterpriseEmploymentPosition({
          projectId: val
        })
          .then(res => {
            res.data.forEach(v => {
              v.label = v.position
              v.value = v.demandId
            })
            this.Positionlist = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    reject (record) {
      const that = this
      this.$confirm({
        title: '驳回确认',
        content: '是否确定要驳回考勤，驳回将不可撤销',
        onOk () {
          employmentEmployReject({ userId: record.userId, demandId: record.demandId }).then(() => {
            that.$message.success('操作成功')
            that.search()
          })
        }
      })
    },
    handleTableChange () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    // 群发信息
    Messaging (val) {
      this.userIdList = Array.from(
        new Set(
          this.selectedRows.map(res => {
            return res.userId
          })
        )
      )
      const params = {
        userIds: this.userIdList,
        content: this.message
      }
      if (val) {
        this.messageLoading = true
        employmentEmployMessaging(params).then(() => {
          this.$message.success('消息群发成功')
          this.handleModalClose()
        }).finally(() => {
          this.messageLoading = false
        })
      } else {
        this.$message.warning('请输入消息内容')
      }
    },
    handleModalClose () {
      this.message = ''
      this.messageVisible = false
    },
    upload (record) {
      this.userId = record.userId
      this.$refs.attendanceDialog.init(record)
    },
    handleGetInfo (userId, demandId) {
      const year = moment(this.month).format('YYYY')
      const month = moment(this.month).format('MM')
      employmentEmployInfo({
        month,
        year,
        userId,
        demandId
      }).then(res => {
        if (res.data.length) {
          const data = res.data.reduce((data, item) => {
            return {
              ...item,
              [`time${item.day}`]: item.time
            }
          }, {})
          this.modelTableData = [data]
        } else {
          this.modelTableData = []
        }
      })
    },
    onChange () {
      this.handleGetInfo(this.userId, this.demandId)
    },
    handleIfShow (row) {
      return row.status === 1 && row.attendanceStatus === 0 && row.toDay != '今日未打卡！'
    },
    handleStop () {
      this.$confirm({
        title: '任务终止确认',
        content: '是否确定要终止任务，终止任务将不可撤销',
        onOk: () => {
            const params = this.selectedRows.map(item => {
               return item.status
            })
        if (!params.includes(0)) {
           employmentEmployStop(
            this.selectedRows.map(item => ({
              userId: item.userId,
              demandId: item.demandId
            }))
          ).then(res => {
            this.$message.success('任务终止成功')
            this.selectedRows = ''
            this.selectedRowKeys = []
            this.search()
          })
        } else {
            this.$message.error('所选数据中存在已经任务终止的数据')
        }
        }
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');
.attendance__wrapper {
  height: 100%;
  .table__header_left {
    .ant-btn {
      margin-right: 10px;
    }
  }
  .content__header {
    margin-bottom: 24px;
  }
  .content {
    height: calc(100% - 44px) !important;
    padding: 24px;
  }
}
</style>
